.skip-links {
    position: fixed;
    z-index: $z-index__off-canvas + 2;
    top: 16px; left: 50%;
    box-shadow: 0 5px 20px rgba($color__black, .16);
    transform: translate(-50%, calc(-100% - 36px));
    transition: transform .2s ease-in-out .2s;

    @include focus-within {
        transform: translate(-50%, 0);
        transition: transform .2s ease-in-out;

        &::before {
            opacity: 1;
            pointer-events: auto;
            transition: opacity .2s linear .1s;
        }
    }

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%; left: 50%;
        width: 200vw;
        height: 200vh;
        background-color: rgba($color__black, .4);
        opacity: 0;
        pointer-events: none;
        transform: translate(-50%, -50%);
        transition: opacity .2s linear;
    }

    &__header {
        padding: 8px 16px;
        font-size: 16px;
        background-color: $color__gray--light;
        // border-bottom: 1px solid $color__gray;
        border-radius: 4px 4px 0 0;
    }

    &__body {
        display: flex;
        flex-flow: row wrap;
        gap: 16px;
        padding: 24px 16px;
        background-color: $color__white;
        border-radius: 0 0 4px 4px;

        button {
            flex: 1;
            white-space: nowrap;
        }
    }
}
